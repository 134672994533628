import { render, staticRenderFns } from "./module-import.vue?vue&type=template&id=7e9763ae&scoped=true&"
import script from "./module-import.vue?vue&type=script&lang=js&"
export * from "./module-import.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9763ae",
  null
  
)

export default component.exports